@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
    /*  local custom font */
    font-family: 'Gobold lowplus';
    src: url('./assets/fonts/Gobold\ Lowplus.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    
}

/* .active::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    top: 22px;
   
    width: 40px;
    height: 2px;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
} */